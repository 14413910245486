import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyCopyBlock } from '../index';

const NSCLC_1L_MONO = () => (
    
    <ComponentWrapper>
         <StudyCopyBlock
            title="Study Overview<sup>1</sup>"
            description="Randomized, open-label, multicenter, active-controlled, phase 3 trial. Included treatment-naïve patients with mNSCLC whose tumors had high PD&#8288;-&#8288;L1 expression (TPS&nbsp;≥50%). Patients with epidermal growth factor receptor (EGFR) or anaplastic lymphoma kinase (ALK) genomic tumor aberrations; an autoimmune disease that required systemic therapy within 2 years of treatment; a medical condition that required immunosuppression; or who had received more than 30 Gy of thoracic radiation within the prior 26 weeks were ineligible. Patients were randomized to receive KEYTRUDA 200&nbsp;mg intravenously every 3 weeks (Q3W) (n=154) or investigator’s choice platinum-containing chemotherapy (n=151; including pemetrexed + carboplatin, pemetrexed + cisplatin, gemcitabine + cisplatin, gemcitabine + carboplatin, or paclitaxel + carboplatin; patients with nonsquamous NSCLC could receive pemetrexed maintenance). Treatment with KEYTRUDA continued until progression of disease, unacceptable toxicity, or for up to 24 months. Patients on chemotherapy who experienced disease progression were offered KEYTRUDA. The main efficacy outcome measure was progression-free survival (PFS) as assessed by BICR per RECIST v1.1 (modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ). Additional efficacy outcome measures were OS and objective response rate (ORR) as assessed by BICR according to RECIST v1.1, modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ."
            definitions={`
                mNSCLC = metastatic NSCLC.            
            `} 
        />
    </ComponentWrapper>
);

export default NSCLC_1L_MONO;